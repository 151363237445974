import React from "react";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
const ResourceByDomain = ({ resources }) => {
  const domainGrouped = resources.reduce((result, item) => {
    const domains = item.name
      .split(",")
      .map((namePart) => {
        const match = namePart.trim().match(/(.+?)\s+(\d+)\s*\(HCs?\)/i);
        return match
          ? {
              domain: match[1].trim(),
              HC: parseInt(match[2], 10),
              position: item.position,
            }
          : null;
      })
      .filter(Boolean);

    domains.forEach(({ domain, HC, position }) => {
      if (!result[domain]) {
        result[domain] = {};
      }
      if (!result[domain][position]) {
        result[domain][position] = 0;
      }
      result[domain][position] += HC;
    });

    return result;
  }, {});

  const groupedArray = Object.entries(domainGrouped).map(
    ([domain, positions]) => ({
      domain,
      positions: Object.entries(positions).map(([position, HC]) => ({
        position,
        HC,
      })),
    })
  );

  const getHCByLevel = (positions) => {
    const levels = { 4: "", 3: "", 2: "", 1: "", OS: "" };
    positions.forEach(({ position, HC }) => {
      if (levels[position] !== undefined) {
        levels[position] = HC;
      }
    });
    return levels;
  };

  // Total HC for each domain and the grand total
  const rowsWithTotals = groupedArray.map((item) => {
    const levels = getHCByLevel(item.positions);
    const totalHC = Object.values(levels).reduce(
      (acc, hc) => acc + (hc || 0),
      0
    );
    return { ...item, levels, totalHC };
  });

  const grandTotalHC = rowsWithTotals.reduce(
    (acc, row) => acc + row.totalHC,
    0
  );

  return (
    <div className="resources">
      <div className="summary-item">
        <div className="flex">
          <h4 className="no-border">
            <UserGroupIcon />
            Vacant By Domain
          </h4>
          <h4 className="no-border total type">{grandTotalHC}</h4>
        </div>
        <div className="detail-item flex no-border scroll height245">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Domain</TableCell>
                  <TableCell align="center">4</TableCell>
                  <TableCell align="center">3</TableCell>
                  <TableCell align="center">2</TableCell>
                  <TableCell align="center">1</TableCell>
                  <TableCell align="center">OS</TableCell>
                  <TableCell align="center">Total HC</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsWithTotals.map((item) => (
                  <TableRow key={item.domain}>
                    <TableCell>{item.domain}</TableCell>
                    <TableCell align="center">{item.levels[4]}</TableCell>
                    <TableCell align="center">{item.levels[3]}</TableCell>
                    <TableCell align="center">{item.levels[2]}</TableCell>
                    <TableCell align="center">{item.levels[1]}</TableCell>
                    <TableCell align="center">{item.levels.OS}</TableCell>
                    <TableCell align="center">{item.totalHC}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default ResourceByDomain;
