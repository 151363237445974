const getStatusCounts = (data, field) => {
  const statusCounts = data.reduce((acc, item) => {
    const statusName =
      field === "status"
        ? item?.fields?.status?.name
        : item?.fields?.[field]?.value;
    if (statusName !== undefined) {
      acc[statusName] = (acc[statusName] || 0) + 1;
    }
    return acc;
  }, {});
  return statusCounts;
};

export default getStatusCounts;
