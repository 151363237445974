import axios from "axios";

export const getResources = () =>
  axios.get(`${process.env.GATSBY_API_ROOT}/pmo-collection/v1/manageServices`);

export const addResources = (params) =>
  axios.post(
    `${process.env.GATSBY_API_ROOT}/pmo-collection/v1/manageServices`,
    params
  );

export const patchResource = (id, params) =>
  axios.patch(
    `${process.env.GATSBY_API_ROOT}/pmo-collection/v1/manageServices/${id}`,
    params
  );

export const deleteResource = (id, params) =>
  axios.delete(
    `${process.env.GATSBY_API_ROOT}/pmo-collection/v1/manageServices/${id}`,
    { data: params }
  );
