import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Fab,
  Paper,
  TextField,
  Button,
  Typography,
  IconButton,
  Slide,
  Fade,
} from "@mui/material";
import {
  Message as MessageIcon,
  Close as CloseIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import axios from "axios";

const chatURL =
  process.env.GATSBY_APP_ENV === "development"
    ? "/api/llm/v1/chat/completions"
    : "https://api.cpxis.global.lotuss.org/llm/v1/chat/completions";

const API_KEY = "automation.lotuss.Zb71t4pjNR3rty3uI8os9jwxaJmU8h";

const MessageContent = ({ content, isUser }) => {
  return (
    <ReactMarkdown
      components={{
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        p: ({ children }) => (
          <Typography
            variant="body1"
            sx={{
              mt: 1,
              mb: 1,
              color: isUser ? "#fff" : "inherit",
              "& code": {
                backgroundColor: isUser
                  ? "rgba(255,255,255,0.1)"
                  : "rgba(0,0,0,0.1)",
                padding: "2px 4px",
                borderRadius: 1,
                fontFamily: "monospace",
              },
            }}
          >
            {children}
          </Typography>
        ),
        ul: ({ children }) => (
          <Box component="ul" sx={{ pl: 2, m: 0 }}>
            {children}
          </Box>
        ),
        li: ({ children }) => (
          <Typography
            component="li"
            sx={{
              color: isUser ? "#fff" : "inherit",
              mb: 0.5,
            }}
          >
            {children}
          </Typography>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

const FloatingChat = ({ position = "right", data }) => {
  const dataContext = `Here's the current data to analyze: ${JSON.stringify(
    data.slice(0, 5)
  )}`;
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      role: "system",
      content: `You are a senior PMO (Project Management Office) professional with 15+ years of experience in enterprise software development. Your expertise includes:
- Advanced data analysis and reporting using project management metrics
- Jira administration and customization at scale (1000+ users)
- Portfolio management and resource optimization
- Agile/Scrum methodologies and best practices
${dataContext}\n\n
Please provide advice that incorporates industry best practices, considers enterprise-scale challenges, and balances ideal approaches with practical implementation.`,
    },
  ]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const getChatCompletion = async (updatedMessages) => {
    try {
      const response = await axios.post(
        chatURL,
        {
          model: "default",
          messages: updatedMessages,
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching chat completion:", error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (!userInput.trim() || isLoading) return;

    setIsLoading(true);
    const updatedMessages = [
      ...messages,
      {
        role: "user",
        content: userInput.trim(),
      },
    ];

    try {
      const data = await getChatCompletion(updatedMessages);
      const assistantReply =
        data?.choices?.[0]?.message?.content || "No response received.";

      setMessages([
        ...updatedMessages,
        { role: "assistant", content: assistantReply },
      ]);
      setUserInput("");
    } catch (error) {
      setMessages([
        ...updatedMessages,
        {
          role: "assistant",
          content: "Error fetching response from the server.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Fade in={!isOpen}>
        <Fab
          color="primary"
          aria-label="chat"
          onClick={() => setIsOpen(true)}
          sx={{
            position: "fixed",
            bottom: 20,
            [position]: 20,
          }}
        >
          <MessageIcon />
        </Fab>
      </Fade>

      <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
        <Paper
          elevation={4}
          sx={{
            position: "fixed",
            bottom: 20,
            [position]: 20,
            width: 360,
            height: 500,
            display: "flex",
            flexDirection: "column",
            zIndex: 999,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "primary.main",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Chat Assistant</Typography>
            <IconButton
              color="inherit"
              onClick={() => setIsOpen(false)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              p: 2,
              backgroundColor: "#f5f5f5",
            }}
          >
            {messages
              .filter((msg) => msg.role !== "system")
              .map((message, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent:
                      message.role === "user" ? "flex-end" : "flex-start",
                    mb: 2,
                  }}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      p: 2,
                      maxWidth: "80%",
                      backgroundColor:
                        message.role === "user" ? "#1976d2" : "#fff",
                      borderRadius: 2,
                      "& pre": {
                        margin: "4px 0",
                        borderRadius: 1,
                        maxWidth: "100%",
                        overflow: "auto",
                      },
                    }}
                  >
                    <MessageContent
                      content={message.content}
                      isUser={message.role === "user"}
                    />
                  </Paper>
                </Box>
              ))}
            <div ref={messagesEndRef} />
          </Box>

          <Box sx={{ p: 2, backgroundColor: "background.paper" }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                size="small"
                placeholder="Type a message..."
                variant="outlined"
                fullWidth
                multiline
                maxRows={3}
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
                disabled={isLoading}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading}
                sx={{
                  minWidth: "unset",
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                }}
              >
                <SendIcon />
              </Button>
            </Box>
          </Box>
        </Paper>
      </Slide>
    </>
  );
};

export default FloatingChat;
