import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DoughnutChart = ({ data, summary }) => {
  const options = {
    plugins: {
      datalabels: {
        display: true,
      },
      legend: {
        display: false,
      },
    },
  };

  const summaryOption = {
    plugins: {
      datalabels: {
        formatter: function (value, context) {
          return value; // Display the value from dataset.data
        },
        color: "#000", // Label text color
        font: {
          size: 14,
          weight: "bold",
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return <Doughnut options={summary ? summaryOption : options} data={data} />;
};

export default DoughnutChart;
