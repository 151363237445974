import React, { useEffect } from "react";
import getResources from "../../../services/getResources";
import { useAdminState } from "../../../context/AdminContext";
import Loading from "../../../shared/Loading";
import { UserGroupIcon } from "@heroicons/react/24/outline";
const Resources = () => {
  const { resources, total, loading } = useAdminState().resources;

  useEffect(() => {
    getResources();
  }, []);

  if (loading) return <Loading />;
  if (!resources) return <div>No resources available</div>;

  const ResourceDetailItem = ({ label, dataKey, resources, className }) => (
    <div className={className}>
      <p>{label}</p>
      {resources
        .filter(({ vendor }) => vendor === "Total Resources")
        .map(({ id, name, [dataKey]: dataValue }) => (
          <div key={id}>
            <h3>
              {dataValue || "N/A"}
              <br />
              <span>({name})</span>
            </h3>
          </div>
        ))}
    </div>
  );

  const resourceConfig = [
    { label: "Total", dataKey: "total", className: "item ontrack" },
    { label: "Active", dataKey: "salary", className: "item inprogress" },
    { label: "Vacant", dataKey: "periodContract", className: "item at-risk" },
  ];

  return (
    <div className="resources">
      <div className="summary-item">
        <div className="flex">
          <h4 className="no-border">
            <UserGroupIcon />
            Resources
          </h4>
          <h4 className="no-border total type">{total}</h4>
        </div>
        <div className="detail-item flex no-border">
          {resourceConfig.map(({ label, dataKey, className }) => (
            <ResourceDetailItem
              key={label}
              label={label}
              dataKey={dataKey}
              resources={resources}
              className={className}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Resources;
