import axios from "axios";

export const getJiraProjectDetail = (id, fields) =>
  axios.get(
    `${process.env.GATSBY_API_ROOT}/pmo-collection/v1/jira/issue/${id}?fields=${fields}`
  );

export const searchJira = (params) => {
  const qs = new URLSearchParams(params);

  return axios.get(
    `${
      process.env.GATSBY_API_ROOT
    }/pmo-collection/v1/jira/search?${qs.toString()}`
  );
};

export const getEpicDetail = (id, params) => {
  const qs = new URLSearchParams(params);
  return axios.get(
    `${
      process.env.GATSBY_API_ROOT
    }/pmo-collection/v1/jira/issue/${id}?${qs.toString()}`
  );
};
