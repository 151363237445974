import React, { useEffect, useMemo, useState } from "react";
import { Wrapper } from "./styles";
import { Container, Box, Grid } from "@mui/material";
import Breadcrumb from "../../shared/Breadcrumbs";
import Loading from "../../shared/Loading";
import DoughnutChart from "../../shared/DoughnutChart";
import StackedBarChart from "../../shared/StackedBarChart";
import Projects from "../DashboardItem/PMO/Projects";
import ThisMonthGoLive from "../DashboardItem/PMO/ThisMonthGoLive";
import HighlightsProjects from "../DashboardItem/PMO/HighlightsProjects";
import Resources from "../DashboardItem/PMO/Resources";
import ResourceByDomain from "../DashboardItem/PMO/ResourcesByDomain";
import DemandProjects from "../DashboardItem/PMO/DemandProjects";
import getJira from "../../services/getJira";
import getStatusCounts from "../../utils/statusCounts";
import { useAdminState } from "../../context/AdminContext";
import { StarIcon, BuildingOffice2Icon } from "@heroicons/react/24/outline";
import FloatingChat from "../Chatbox";
function PMODashboard() {
  const { jira, loading } = useAdminState().jira;
  const { resources } = useAdminState().resources;
  const { login: { user: { profile } = {}, role } = {} } = useAdminState();
  const [cancelled, setCancelled] = useState("");
  const [demand, setDemand] = useState([]);
  const [IPP, setIPP] = useState([]);
  const [status, setStatus] = useState({});

  useEffect(() => {
    if (!jira.length) {
      getJira(
        `(project = IPP AND duedate >= "2024-01-01" AND duedate <= endOfYear(+1y)) OR (project = DEM AND status NOT IN ("Done", "On Hold", "Cancel", "Submit To Portfolio"))`,
        "summary, status, duedate, assignee, customfield_10665, customfield_10675, customfield_10666, customfield_11779, customfield_10670, customfield_10678, customfield_10668, customfield_10671, customfield_10651"
      );
    }
  }, [jira]);

  useEffect(() => {
    if (jira.length) {
      setDemand(jira.filter((item) => item?.key?.startsWith("DEM-")));
      setIPP(jira.filter((item) => item?.key?.startsWith("IPP-")));
    }
  }, [jira]);

  const chartData = useMemo(() => {
    const statusCounts = getStatusCounts(jira, "status");
    const transformed = {
      "In Progress":
        (statusCounts["Active"] || 0) +
        (statusCounts["ACTIVE (Project Highlight)"] || 0) +
        (statusCounts["Open from DM (temp.)"] || 0),
      Completed: statusCounts["Completed"] || 0,
      "On Hold": statusCounts["On Hold"] || 0,
    };

    setStatus(transformed);
    setCancelled(statusCounts["Cancelled"] || 0);

    return {
      labels: Object.keys(transformed),
      datasets: [
        {
          data: Object.values(transformed),
          backgroundColor: [
            "rgba(254, 200, 77, 0.8)",
            "rgba(0, 177, 176, 0.5)",
            "rgba(253, 126, 64, 0.8)",
          ],
        },
      ],
    };
  }, [jira]);

  const byDomainData = useMemo(() => {
    const transformed = IPP.reduce((acc, item) => {
      const category = item?.fields?.customfield_10666?.value;
      const riskStatus = item?.fields?.customfield_10678?.value;
      if (!category || !riskStatus) return acc;
      if (!acc[category]) acc[category] = {};
      if (!acc[category][riskStatus]) acc[category][riskStatus] = 0;

      acc[category][riskStatus] += 1;

      return acc;
    }, {});

    const categories = Object.keys(transformed);
    const riskStatuses = Array.from(
      new Set(Object.values(transformed).flatMap((obj) => Object.keys(obj)))
    );

    return {
      labels: categories,
      datasets: riskStatuses.map((status) => ({
        label: status,
        data: categories.map((category) => transformed[category][status] || 0),
        backgroundColor: status.includes("Green")
          ? "rgba(0, 177, 176, 0.5)"
          : status.includes("Yellow")
          ? "rgba(254, 200, 77, 0.8)"
          : "rgba(253, 126, 64, 0.8)",
      })),
    };
  }, [IPP]);

  return (
    <Wrapper>
      <Container className="container">
        <div className="page-header">
          <h2>PMO Dashboard</h2>
          <div className="flex">
            <Breadcrumb page="PMO Dashboard" link="#" />
          </div>
          <p className="welcome">
            Hi, <span>{profile?.given_name || ""}</span>. Welcome back to
            Project Status Automation report!
          </p>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <div className="dashboard-wrapper team-dashboard">
              {(process.env.GATSBY_APP_ENV === "development" ||
                role === "SuperAdmin") && <FloatingChat position="right" data={jira} />}
              <Grid container spacing={4}>
                <Grid item xs={12} md={5}>
                  <div className="summary-item project-sum">
                    <div className="flex">
                      <h4 className="no-border">
                        <StarIcon />
                        Project Summary (number of projects)
                      </h4>
                    </div>
                    <div className="detail-item flex no-border">
                      <div className="pie-chart-container total-projects">
                        <div className="total">
                          <h2>Total</h2>
                          <p>{IPP.length}</p>
                        </div>

                        {chartData ? (
                          <DoughnutChart data={chartData} summary />
                        ) : (
                          <p>No data available</p>
                        )}

                        <div className="summary-status">
                          <ul>
                            {Object.entries(status).map(
                              ([key, count], index) => (
                                <li key={key} className={`dot-${index + 1}`}>
                                  {key}
                                </li>
                              )
                            )}
                            <li className="cancelled">
                              Cancelled: <span>{cancelled}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={7}>
                  <div className="summary-item project-sum">
                    <div className="flex">
                      <h4 className="no-border">
                        <BuildingOffice2Icon />
                        Project By Domain
                      </h4>
                    </div>
                    <div className="detail-item flex no-border">
                      <div className="number-of-project"># of projects</div>
                      {byDomainData ? (
                        <StackedBarChart data={byDomainData} />
                      ) : (
                        <p>No data available</p>
                      )}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Projects project={IPP} />
                </Grid>

                <Grid item xs={12}>
                  <HighlightsProjects project={IPP} />
                </Grid>

                <Grid item xs={12}>
                  <ThisMonthGoLive project={IPP} />
                </Grid>

                <Grid item xs={12} md={12}>
                  <DemandProjects demand={demand} />
                </Grid>

                {(role === "SuperAdmin" || role === "Admin") && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Resources />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <ResourceByDomain resources={resources || []} />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </Box>
        )}
      </Container>
    </Wrapper>
  );
}

export default PMODashboard;
