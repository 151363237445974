import React, { useState } from "react";
import { Link } from "gatsby";
import moment from "moment/moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StarIcon } from "@heroicons/react/24/outline";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const sortDataByDueDate = (data) => {
  return data.sort((a, b) => {
    const dateA = moment(a.fields.duedate);
    const dateB = moment(b.fields.duedate);
    return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
  });
};

const getCurrentMonthAndYear = () => {
  const date = new Date();
  return {
    month: date.getMonth() + 1, // 1-12
    year: date.getFullYear(),
  };
};

const filterByMonth = (data, selectedMonth, selectedYear) => {
  return data.filter((item) => {
    const duedate = item.fields.duedate;
    if (!duedate) return false;

    const [dueYear, dueMonth] = duedate.split("-").map(Number);
    return dueYear === selectedYear && dueMonth === selectedMonth;
  });
};

const ThisMonthGoLive = ({ project }) => {
  const { month: currentMonth, year: currentYear } = getCurrentMonthAndYear();
  const [selectedDate, setSelectedDate] = useState(
    new Date(currentYear, currentMonth - 1)
  );

  const filteredData = filterByMonth(
    project,
    selectedDate.getMonth() + 1,
    selectedDate.getFullYear()
  );
  const sortedData = sortDataByDueDate(filteredData);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const months = moment.months(); // Get all month names

  const handlePreviousMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setSelectedDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setSelectedDate(newDate);
  };

  return (
    <div className="summary-item">
      <div className="flex">
        <h4 className="no-border line-height40">
          <StarIcon />
          Project Go Live in {moment(selectedDate).format("MMMM YYYY")}
        </h4>
        <h4 className="no-border">
          <span className="flex no-border go-live">
            <IconButton onClick={handlePreviousMonth}>
              <ArrowBackIos />
            </IconButton>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={selectedDate}
                className="picker"
                onChange={handleDateChange}
                views={["month", "year"]}
                size="small"
                renderInput={(params) => (
                  <input
                    {...params}
                    style={{ fontSize: "0.875rem", padding: "5px" }}
                  />
                )}
              />
            </LocalizationProvider>

            <IconButton onClick={handleNextMonth}>
              <ArrowForwardIos />
            </IconButton>
          </span>
        </h4>
      </div>
      <div className="detail-item flex no-border">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Project Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.length > 0 ? (
                sortedData.map((i, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor:
                        index % 2 === 0 ? "rgb(227, 227, 227)" : "white",
                    }}
                  >
                    <TableCell>
                      <Link to={`/project-details/pmo?id=${i.key}`}>
                        {i.fields.summary}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {moment(i.fields.duedate).format("D MMM YYYY")}
                    </TableCell>
                    <TableCell>{i.fields.status.name}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No projects scheduled for this month
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ThisMonthGoLive;
